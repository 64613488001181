import { DEFAULT_API_URLS } from "../../enum";
import * as environment from "../../environment";
import axios from "axios";

const urlApi = DEFAULT_API_URLS[environment.getEnvironment()];

export async function ReadCertificateSafeIdController(access_token: string) {
  try {
    const response = await axios.get(`${urlApi}/ReadCertificateSafeId/`, {
      headers: {
        "Content-Type": "application/json",
        access_token,
      },
    });
    return response.data; // Retornamos apenas os dados para facilitar o uso
  } catch (error) {
    console.error("Erro na requisição à API:", error);
    throw error; // Propaga o erro para ser tratado na função chamadora
  }
}
