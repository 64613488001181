import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../globals/AuthContext";

//Chamada da api
import { ReadCertificateSafeIdController } from "../../utils/controllers/RequestAuthentication/ReadCertificateSafeIdController";

interface CallbackMessageModel {
  Status: string;
  Message: string;
}

interface CertificateModel {
  CpfCnpj: string;
  NomeRazaoSocial: string;
}

const Header: React.FC = () => {
  const location = window.location.href;
  // const location = "/?auth=RmRKeWw0TU1ZQTQwTW1zMDNlTGloQ2wrKw==";

  const { accessToken, setAccessToken, setCertificate, setAutenticated } =
    useContext(AuthContext);

  const hasCalledApi = useRef(false); // Flag para evitar múltiplas chamadas
  const navigate = useNavigate();

  const renderReadCertificateSafeId = useCallback(
    (res: unknown) => {
      try {
        if (res && typeof res === "object") {
          if ("Status" in res && typeof res.Status === "string") {
            const callbackMessage = res as CallbackMessageModel;

            if (callbackMessage.Status === "Erro") {
              console.error(
                "Erro retornado pela API:",
                callbackMessage.Message
              );
              return;
            }

            if (callbackMessage.Status === "Warning") {
              console.warn(
                "Aviso retornado pela API:",
                callbackMessage.Message
              );
              return;
            }

            if (callbackMessage.Status === "Ok") {
              console.log("Ok retornado pela API:", callbackMessage.Message);
              return;
            }
          } else if ("CpfCnpj" in res && "NomeRazaoSocial" in res) {
            const certificate = res as CertificateModel;
            setCertificate(certificate);
            setAutenticated(true);
          } else {
            console.error("Resposta da API não contém o formato esperado.");
          }
        } else {
          console.error("Formato de resposta inválido:", res);
        }
      } catch (error) {
        console.error("Erro ao processar a resposta da API:", error);
      }
    },
    [setAutenticated, setCertificate]
  );

  const readCertificateSafeId = useCallback(
    async (access_token: string): Promise<void> => {
      try {
        const response = await ReadCertificateSafeIdController(access_token);
        renderReadCertificateSafeId(response);
      } catch (error) {
        console.error("Erro ao realizar a requisição do Access Token:", error);
        navigate("/");
      }
    },
    [navigate, renderReadCertificateSafeId]
  );

  useEffect(() => {
    if (!hasCalledApi.current && accessToken === "") {
      const access_token = location.split("?auth=")[1];

      if (access_token) {
        setAccessToken(access_token);
        readCertificateSafeId(access_token);
        hasCalledApi.current = true; // Marca como chamado
      }
    }
  }, [accessToken, location, readCertificateSafeId, setAccessToken]);

  return <></>;
};

export default Header;
