// export function getEnvironment() {
//   if (window.location.host.match("localhost")) {
//     return "LOCAL";
//     // return "PRODUCTION";
//   }
//   // else if (window.location.host.match("agrocomply-desenv")) {
//   //     return "DEVELOPMENT";
//   // }
//   // else if (window.location.host.match("agrocomply-teste")) {
//   //     return "TEST";
//   // }
//   // else if (window.location.host.match("homologacao")) {
//   //     return "STAGING";
//   // }
//   else if (window.location.host.match("agrocomply")) {
//     return "PRODUCTION";
//   }
// }

import { DEFAULT_API_URLS } from "./enum";

export function getEnvironment(): keyof typeof DEFAULT_API_URLS {
  if (window.location.host.match("localhost")) {
    return "LOCAL";
  } else if (window.location.host.match("agrocomply")) {
    return "PRODUCTION";
  }
  throw new Error("Ambiente não reconhecido!");
}
