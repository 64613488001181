import React, { useContext } from "react";
import { AuthContext } from "../../globals/AuthContext";

import styles from "../../styles/pages/Modulo1.module.css";
import stylesAuthLogo from "../../styles/pages/AuthLogo.module.css";
import logo from "../../assets/images/logos/logo.png";

const Modulo1: React.FC = () => {
  const { certificate, autenticated } = useContext(AuthContext);

  return (
    <div id="modulo1">
      {autenticated ? (
        <div className={styles.Modulo1}>
          <header className={styles["Modulo1-header"]}>
            <h1>Seja muito bem-vindo(a)</h1>
            <form className={styles["Modulo1-form"]}>
              <div className={styles["Modulo1-field"]}>
                <label htmlFor="nomeRazaoSocial">
                  {certificate.NomeRazaoSocial}
                </label>
              </div>
              <div className={styles["Modulo1-field"]}>
                <label htmlFor="cpfCnpj">{certificate.CpfCnpj}</label>
              </div>
            </form>
          </header>
        </div>
      ) : (
        <div className={stylesAuthLogo.Auth}>
          <header className={stylesAuthLogo["Auth-header"]}>
            <img
              src={logo}
              className={stylesAuthLogo["Auth-logo"]}
              alt="logo"
            />
          </header>
        </div>
      )}
    </div>
  );
};

export default Modulo1;
