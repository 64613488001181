// export const DEFAULT_API_URLS = {
//   LOCAL: "https://localhost:7053",
//   // LOCAL: "https://api-authentication.agrocomply.com.br",
//   DEVELOPMENT: "",
//   TEST: "",
//   STAGING: "",
//   PRODUCTION: "https://api-authentication.agrocomply.com.br",
// };
export const DEFAULT_API_URLS = {
  // LOCAL: "https://localhost:7053",
  LOCAL: "https://api-authentication.agrocomply.com.br",
  DEVELOPMENT: "",
  TEST: "",
  STAGING: "",
  PRODUCTION: "https://api-authentication.agrocomply.com.br",
} as const;
