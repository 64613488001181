import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";

// Importando imagens diretamente
import AgrocomplyLogo from "../../assets/images/logos/agrocomply-logo.png";
import SafeIdLogo from "../../assets/images/logos/safeid-logo.png";
import styles from "../../styles/pages/Home.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const Home: React.FC = () => {
  const [currentPost, setCurrentPost] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const posts = [
    {
      content:
        "Padrões <br />Seguros de<br />Rastreabilidade<br />Agropecuária",
    },
    {
      content: "Inovação e<br /> Sustentabilidade <br /> no Agro",
    },
    {
      content: "Compliance e<br />Segurança nas <br /> Operações Rurais",
    },
  ];

  const handleLogin = () => {
    window.location.href =
      "https://pscsafeweb.safewebpss.com.br/Service/Microservice/OAuth/api/v0/oauth/authorize?response_type=code&client_id=agrocomply-authentication-hbauwnvm&redirect_uri=https%3a%2f%2fauthentication.agrocomply.com.br%2fauthentication%2f&state=zRLDN0xx0d4091PIROXgnl208gsmg8h3peu2oBlxo0EwvF80qj&lifetime=3600&scope=signature_session&code_challenge=F6hFrB5m0i0bWD9pTJC-hCGTX0xi2dy7v1AR5jjbQoM&code_challenge_method=S256";
  };

  const validateForm = () => {
    let isValid = true;

    if (!email) {
      setEmailError("Campo obrigatório");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("E-mail inválido");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Campo obrigatório");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Formulário enviado");
      // Coloque a lógica de envio aqui
    }
  };

  const handleHexagonClick = (index: number) => {
    setCurrentPost(index);
  };

  // Carrossel automático
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPost((prevPost) => (prevPost + 1) % posts.length);
    }, 5000); // Troca a cada 5 segundos

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [posts.length]);

  return (
    <div className={styles.Home}>
      {/* Seção Esquerda */}
      <div className={styles.leftSection}>
        <img
          src={AgrocomplyLogo}
          alt="Agrocomply Logo"
          className={styles.logo}
        />
        <h1>Bem-vindo(a) à Agrocomply!</h1>
        <p>Para entrar, insira seus dados:</p>

        {/* Substituído o Container */}
        <div className={styles.customContainer}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail" className={styles.formGroup}>
              <Form.Label className={styles.label}>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="Digite seu e-mail"
                className={`${styles.input} ${
                  emailError ? styles.inputError : ""
                }`}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!e.target.value) {
                    setEmailError("Campo obrigatório");
                  } else if (!/\S+@\S+\.\S+/.test(e.target.value)) {
                    setEmailError("E-mail inválido");
                  } else {
                    setEmailError("");
                  }
                }}
              />
              {emailError && (
                <p className={styles.errorText}>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className={styles.errorIcon}
                  />
                  {emailError}
                </p>
              )}
            </Form.Group>

            <Form.Group controlId="formPassword" className={styles.formGroup}>
              <Form.Label className={styles.label}>Senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="Digite sua senha"
                className={`${styles.input} ${
                  passwordError ? styles.inputError : ""
                }`}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (!e.target.value) {
                    setPasswordError("Campo obrigatório");
                  } else {
                    setPasswordError("");
                  }
                }}
              />
              {passwordError && (
                <p className={styles.errorText}>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className={styles.errorIcon}
                  />
                  {passwordError}
                </p>
              )}
            </Form.Group>

            <Button type="submit" className={styles.submitBtn}>
              Entrar
            </Button>
          </Form>

          <div className={styles.linkContainer}>
            <a href="/" className={styles.forgotPassword}>
              Esqueci minha senha
            </a>
            <p>
              Não tem uma conta?{" "}
              <a href="/" className={styles.createAccount}>
                Crie uma agora mesmo!
              </a>
            </p>
          </div>

          <div className={styles.partnerAccess}>
            <Form.Label className={styles.label}>
              Acesso clientes e parceiros:
            </Form.Label>
            <Button className={styles.partnerButton} onClick={handleLogin}>
              <img
                src={SafeIdLogo}
                alt="SafeId Logo"
                className={styles.safeIdLogo}
              />
            </Button>
          </div>
        </div>
      </div>

      {/* Seção Direita com Background */}
      <div
        className={`${styles.rightSection} ${styles.backgroundImage} ${
          styles[`background${currentPost}`]
        }`}
      >
        <div className={styles.latestPosts}>
          <h3 className={styles.postsTitle}>Últimos Posts</h3>
          <p
            className={styles.postsContent}
            dangerouslySetInnerHTML={{ __html: posts[currentPost].content }}
          />
          <div className={styles.hexagonContainer}>
            {posts.map((_, index) => (
              <Button
                key={index}
                className={`${styles.hexagon} ${
                  currentPost === index ? styles.activeHexagon : ""
                }`}
                onClick={() => handleHexagonClick(index)}
              ></Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
